import Axios from "axios"

Axios.defaults.baseURL = window.location.protocol + "//" + window.location.host + "/api/mobile"
Axios.defaults.withCredentials = true
const Request = (config: any) => {
    return Axios.request({...config})
}
// 获取ui配置
export const getTenantSetting = (uuid: string) => {
    return Request({
        url: "/tenant/setting",
        method: "get",
        headers: {"Content-Type": "application/json", tenant: uuid}
    })
}