import {createStore} from "redux"
import {persistReducer, persistStore} from "redux-persist"
import storage from "redux-persist/lib/storage"
import {composeWithDevTools} from "redux-devtools-extension"

const persistConfig = {key: 'root', storage}

let defaultStates: any = {
    header: "AI门岗",
    client: null,
    showTab: false,
    tabKey: null,
    user: null,
    tenant: null,
    url: "/home",
    title: "AI门岗"
}
const reducer = (state: any = defaultStates, action: any) => {
    let newState = JSON.parse(JSON.stringify(state))
    switch (action.type) {
        case "setGlobalHeader":
            newState.header = action.value
            return newState
        case "setGlobalTab":
            newState.showTab = action.value.flag
            if (action.value.key !== null) {
                newState.tabKey = action.value.key
            }
            return newState
        case "setGlobalClient":
            newState.client = action.value
            return newState
        case "setGlobalUser":
            newState.user = action.value
            return newState
        case "setGlobalTenant":
            newState.tenant = action.value
            return newState
        case "setGlobalUrl":
            newState.url = action.value
            newState.title = newState.header
            return newState
        default:
            return newState
    }
}
const persistedReducer = persistReducer(persistConfig, reducer)
export const store = createStore(persistedReducer, composeWithDevTools())
export const persist = persistStore(store)
export const getGlobalState = (key = null as any) => {
    let state = JSON.parse(JSON.stringify(store.getState()))
    return key ? state[key] : state
}
export const setGlobalHeader = (key: string) => {
    window.document.title = key
    store.dispatch({type: "setGlobalHeader", value: key})
}
export const setGlobalTab = (flag: boolean, key = null as (string | null)) => store.dispatch({
    type: "setGlobalTab",
    value: {flag, key}
})
export const setGlobalClient = (client: string) => store.dispatch({type: "setGlobalClient", value: client})
export const setGlobalUser = (user: string) => store.dispatch({type: "setGlobalUser", value: user})
export const setGlobalUrl = (url: string) => store.dispatch({type: "setGlobalUrl", value: url})
export const setGlobalTenant = (tenant: string) => store.dispatch({type: "setGlobalTenant", value: tenant})
