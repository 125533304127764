import React, {useEffect, useState} from "react"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton"
import {store} from "./utils/store"
import {TabBar} from "antd-mobile"
import dd from "dingtalk-jsapi"
import VConsole from "vconsole"
import {getTenantSetting} from './utils/service'
import homeBGUrl from './assets/home.png'
import recordBGUrl from './assets/record.png'
import userBGUrl from './assets/user.png'

const App = (props: any) => {
    const [header, setHeader] = useState<string>(props.getGlobalState("header"))
    const [selectedTab, setSelectedTab] = useState<string>(props.getGlobalState("tabKey"))
    const [hidden, setHidden] = useState<boolean>(!props.getGlobalState("showTab"))
    const [showHeader, setShowHeader] = useState<boolean>(false)
    const [isIOS, setIsIOS] = useState<boolean>(false)
    const [client, setClient] = useState<any>(null)
    const [isPhoneX, setIsPhoneX] = useState<boolean>(false)
    const clientCheck = () => {
        const _userAgent = navigator.userAgent
        let _broMark = null
        if (dd.env.platform !== 'notInDingTalk') {
            _broMark = 'DT'
        } else {
            if (/MicroMessenger/gi.test(_userAgent) && /wxWork/gi.test(_userAgent)) {
                _broMark = 'WW'
            } else if (/MicroMessenger/gi.test(_userAgent) && !/wxWork/gi.test(_userAgent)) {
                _broMark = 'WX'
            } else if (/Lark/gi.test(_userAgent)) {
                _broMark = 'LK'
            } else if (/Mobile/gi.test(_userAgent)) {
                _broMark = 'WB'
            }
        }
        return _broMark
    }
    const matchParams = () => {
        let obj: any = {}
        if (window.location.search) {
            let keyValue = window.location.search.split('?')[1].split('&')
            for (let i = 0; i < keyValue.length; i++) {
                let item = keyValue[i].split('=')
                obj[item[0]] = item[1]
            }
        }
        return obj
    }
    const init = () => {
        let client = clientCheck()
        setClient(client)
        let ua = window.navigator.userAgent
        let IOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        setIsIOS(IOS)
        if (IOS && window.screen.height > 700) {
            setIsPhoneX(true)
        }
        if (client === null) {
            if (window.location.pathname.split("/")[1] === "swagger") {
                window.location.href = window.location.protocol + "//" + window.location.host + '/swagger/'
            } else {
                window.location.href = window.location.protocol + "//" + window.location.host + '/dashboard/'
            }
        } else if (client === "WB"&&window.location.pathname.indexOf("/quick")===-1) {
            setShowHeader(true)
        }
        if (props.getGlobalState("client") !== client) {
            props.setGlobalClient(client)
        }
        if (props.getGlobalState("showTab") === hidden) {
            setHidden(!props.getGlobalState("showTab"))
        }
        if (props.getGlobalState("header") !== header) {
            setHeader(props.getGlobalState("header"))
        }
        if (props.getGlobalState("tabKey") !== selectedTab) {
            setSelectedTab(props.getGlobalState("tabKey"))
        }
    }
    store.subscribe(init)

    useEffect(() => {
        let params = matchParams()
        let tenant
        if (params["tenant"]) {
            let originTenant = props.getGlobalState("tenant")
            if (originTenant === params["tenant"]) {
                tenant = params["tenant"]
                props.setGlobalTenant(params["tenant"])
            } else {
                props.setGlobalUser(null)
                props.setGlobalTab(false)
                window.localStorage.clear()
                props.setGlobalTenant(params["tenant"])
                if (window.location.pathname.split("/")[1] === "auth" && window.location.pathname.split("/")[2] === "ticket") {
                    props.goto("电子凭证", "/auth/ticket"+ window.location.search)
                }else{
                    props.goto("用户登录", "/auth")
                }
            }
        } else {
            tenant = props.getGlobalState("tenant")
        }
        if (window.location.pathname.split("/")[1] === "auth" && window.location.pathname.split("/")[2] === "ticket") {
            props.goto("电子凭证", "/auth/ticket"+ window.location.search)
        }
        if (props.getGlobalState("user") === null && window.location.pathname.split("/")[1] !== "auth") {
            props.setGlobalUrl(window.location.pathname + window.location.search)
            props.goto("用户登录", "/auth")
        }
        init()
        if (tenant&&window.location.pathname.indexOf("/quick")===-1) {
            getTenantSetting(tenant).then(({data}) => {
                if (data.data.isDebug === 1) {
                    // @ts-ignore
                    new VConsole()
                }
            })
        }
        if (props.getGlobalState("user") !== null && tenant !== null && window.location.pathname === "/") {
            let user = props.getGlobalState("user");
            if (user.isModify === 0) {
                props.goto("用户登录", "/auth")
            } else if (!user.expire || user.expire < (new Date()).getTime()) {
                props.setGlobalUser(null)
                props.setGlobalTab(false)
                window.localStorage.clear()
                props.setGlobalTenant(tenant)
                props.goto("用户登录", "/auth")
            } else {
                props.goto("首页", "/home")
            }
        }
    }, [])
    const changeTab = (value: string) => {
        switch (value) {
            case "home":
                props.goto("首页", "/home")
                break
            case "record":
                props.goto("记录", "/record")
                break
            case "user":
                props.goto("我的", "/user")
                break
            default:
                break
        }
    }

    return (
        <>
            {showHeader && (
                <div className="header-box font-size-16">
                    <div className="header-title">{header}</div>
                </div>)}
            {props.loading ?
                (
                    <div
                        style={showHeader ? {
                                minHeight: isIOS ? (isPhoneX ? "calc(94vh - 108px)" : "calc(94vh - 78px)") : "94vh",
                                marginTop: "6vh"
                            } :
                            {
                                margin: "0", overflow: "hidden"
                            }}>
                        <div style={{fontSize: 20}}>
                            <div style={{fontSize: 20, lineHeight: 2, float: 'left', marginLeft: '.21rem'}}>
                                <SkeletonTheme color="#e1e8ee" highlightColor="#cfe0ef">
                                    <p><Skeleton circle={true} count={1} width={70} height={70} duration={1.5}
                                                 delay={1}/></p>
                                </SkeletonTheme>
                            </div>
                            <div style={{width: '70%', float: 'left', margin: '.4rem 0 0 .4rem'}}>
                                <SkeletonTheme color="#e1e8ee" highlightColor="#cfe0ef">
                                    <p><Skeleton count={1} width={"25%"} height={10} duration={1.5} delay={1}/></p>
                                </SkeletonTheme>
                                <SkeletonTheme color="#e1e8ee" highlightColor="#cfe0ef">
                                    <p><Skeleton count={1} width={"55%"} height={10} duration={1.5} delay={1}/></p>
                                </SkeletonTheme>
                            </div>
                        </div>
                        <div style={{margin: "0 10px"}}>
                            <SkeletonTheme color="#e1e8ee" highlightColor="#cfe0ef">
                                <p><Skeleton count={1} width={"75%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"100%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"90%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"25%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"100%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"40%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"70%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"100%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"100%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"40%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"100%"} height={15} duration={1.5} delay={1}/></p>
                                <p><Skeleton count={1} width={"80%"} height={15} duration={1.5} delay={1}/></p>
                            </SkeletonTheme>
                        </div>
                    </div>
                ) : null}

            {/* <div id="subApp-viewport"
                style={showHeader ? {
                    height: isIOS ? (isPhoneX ? "calc(94vh - 108px)" : "calc(94vh - 78px)") : "94vh",
                    marginTop: "6vh"
                } :
                    {
                        height: client === "WW" ? "90vh" : "100vh", margin: "0", overflow: "hidden"
                    }}>
            </div> */}
            <div id="subApp-viewport"
                 style={{
                     marginTop: showHeader ? "6vh" : "",
                     minHeight: showHeader ? "94vh" : "95vh",
                     width: "100vw",
                     paddingBottom: hidden ? "" : "1.3333rem",
                     backgroundColor: "white"
                 }}>
            </div>
            <div
                className="main-footer"
                style={{
                    position: 'fixed',
                    width: "100%",
                    left: 0,
                    bottom: 0,
                    backgroundColor: "#fff",
                    paddingBottom: ((client === "WW" || client === "LK") && isPhoneX) ? ".53333rem" : "",
                    display: (hidden ? "none" : "block")
                }}
            >
                <TabBar
                    unselectedTintColor="#888888"
                    tintColor="#108EE9"
                    hidden={hidden}
                >
                    <TabBar.Item
                        title="首页"
                        key="home"
                        icon={{uri: homeBGUrl}}
                        selectedIcon={{uri: homeBGUrl}}
                        selected={selectedTab === "home"}
                        onPress={() => {
                            changeTab("home")
                        }}
                    >
                    </TabBar.Item>
                    <TabBar.Item
                        icon={{uri: recordBGUrl}}
                        selectedIcon={{uri: recordBGUrl}}
                        title="记录"
                        key="record"
                        selected={selectedTab === "record"}
                        onPress={() => {
                            changeTab("record")
                        }}
                    >
                    </TabBar.Item>
                    <TabBar.Item
                        icon={{uri: userBGUrl}}
                        selectedIcon={{uri: userBGUrl}}
                        title="我的"
                        key="user"
                        selected={selectedTab === "user"}
                        onPress={() => {
                            changeTab("user")
                        }}
                    >
                    </TabBar.Item>
                </TabBar>
            </div>
        </>
    )
}

export default App