import React from 'react'
import ReactDOM from 'react-dom'
import 'lib-flexible'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { initGlobalState, MicroAppStateActions, registerMicroApps, start } from 'qiankun'
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import {
    store,
    persist,
    getGlobalState,
    setGlobalHeader,
    setGlobalTab,
    setGlobalClient,
    setGlobalUser,
    setGlobalUrl,
    setGlobalTenant
} from "./utils/store"
import "reseter.css/css/reseter.min.css"
import 'antd-mobile/dist/antd-mobile.css'
import './stylesheet/common.css'
import './stylesheet/dyform.css'


const hostUrl = window.location.host.split(":")[0]

const actions: MicroAppStateActions = initGlobalState(getGlobalState())

const goto = (title: string, href: string) => {
    setGlobalHeader(title)
    window.history.pushState({}, title, href)
}

const redirect = (url: string) => {
    window.location.href = url
}

const Render = (props: any) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate persistor={persist} loading={null}>
                    <App {...props} />
                </PersistGate>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

// 页面加载
const loader = (loading: boolean) => Render({
    loading,
    getGlobalState,
    setGlobalHeader,
    setGlobalTab,
    setGlobalClient,
    setGlobalUser,
    setGlobalUrl,
    setGlobalTenant,
    goto, redirect,
    ...actions
})

Render({
    loading: true,
    getGlobalState,
    setGlobalHeader,
    setGlobalTab,
    setGlobalClient,
    setGlobalUser,
    setGlobalUrl,
    setGlobalTenant,
    goto, redirect,
    ...actions
})
if (process.env.NODE_ENV === 'development') {
    registerMicroApps([
        {
            name: 'auth',
            entry: '//'+hostUrl+':9601',
            container: '#subApp-viewport',
            loader,
            activeRule: '/auth',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'door',
            entry: '//'+hostUrl+':9602',
            container: '#subApp-viewport',
            loader,
            activeRule: '/door',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'home',
            entry: '//'+hostUrl+':9603',
            container: '#subApp-viewport',
            loader,
            activeRule: '/home',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'naire',
            entry: '//'+hostUrl+':9604',
            container: '#subApp-viewport',
            loader,
            activeRule: '/naire',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'record',
            entry: '//'+hostUrl+':9605',
            container: '#subApp-viewport',
            loader,
            activeRule: '/record',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'user',
            entry: '//'+hostUrl+':9606',
            container: '#subApp-viewport',
            loader,
            activeRule: '/user',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
    ], {})
} else {
    registerMicroApps([
        {
            name: 'auth',
            entry: '/auth-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/auth',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'door',
            entry: '/door-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/door',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'home',
            entry: '/home-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/home',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'naire',
            entry: '/naire-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/naire',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'record',
            entry: '/record-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/record',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
        {
            name: 'user',
            entry: '/user-ui/',
            container: '#subApp-viewport',
            loader,
            activeRule: '/user',
            props: {
                getGlobalState,
                setGlobalHeader,
                setGlobalTab,
                setGlobalClient,
                setGlobalUser,
                setGlobalUrl,
                setGlobalTenant,
                goto, redirect,
            }
        },
    ], {})
}
start()
reportWebVitals()
